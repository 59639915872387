<script>

export default {
    name: "ReclutamientoView",
}
</script>
<template>
    <div class="container-fuild px-0">
        <header class="header bg-primary px-3 py-3 d-flex justify-content-between align-items-center">
            <router-link :to="{ name: 'ListaDeVacantes' }">
                <img src="/img/HXLogoWhite.png" alt="Logo" width="200">
            </router-link>
            <nav>
                <router-link class="nav-item" :to="{ name: 'ListaDeVacantes'}">Lista de vacantes</router-link>
                <router-link class="nav-item text-dark" :to="{ name: 'NavegacionAspirante'}">Seguimiento de postulación</router-link>
            </nav>
        </header>
        <div class="py-2 mb-5">
            <router-view>
            </router-view>
        </div>
    </div>
</template>
<style scoped>
/* .header {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    width: 98%;
    margin: 0 auto;
}
.header * {
    color: #f5f5f5;
} */
nav .router-link-active {
    color: #ffffff;
}
.nav-item {
    font-size: 1.2rem;
    font-weight: 500;
}

.nav-item::before {
    padding: 2rem;
    content: "|";
    color: #f5f5f5;
}
.nav-item:first-child::before {
    content: "";
}

</style>